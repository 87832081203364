class Analytics {
  constructor() {
    this.dev = false;
    this.pollNum = 4;
    this.pollTime = 1000;
    this.defaultObj = {
      type: "dynamic-page",
      data: {
        app_name: "no value",
        content_type: "adbp:none",
        episode_num: "no value",
        mvpd: "no value",
        mvpd_status: "no value",
        network: "no value",
        season_num: "no value",
        series_name: "no value",
        user_id: "no value"
      }
    };
    this.objArray = new Set();
    this.init();
  }

  init() {
    if (this.checkWindow()) {
      this.trackMetrics = window.trackMetrics;
    } else {
      console.warn("Window object was not found. Waiting...");

      if (this.dev)
        typeof window.trackMetrics === "undefined"
          ? (window.trackMetrics = () => {})
          : null;

      this.initPolling(0);
    }
  }

  initPolling(num) {
    window.setTimeout(() => {
      if (this.checkWindow()) {
        this.trackMetrics = window.trackMetrics;
        this.fireCall();
      } else {
        if (num > this.pollNum) {
          console.error("Window object was still not found. Ending polling.");
          return;
        } else {
          console.warn("Window object was still not found. Retrying...");
          num++;
          this.initPolling(num);
        }
      }
    }, this.pollTime);
  }

  bindTracking(payload) {
    this.storeCall(payload);
    this.fireCall();
    console.table(payload);
  }

  checkWindow() {
    return window.trackMetrics && typeof window.trackMetrics === "function";
  }

  formData(payload) {
    const data = {
      type: payload.type ? payload.type : this.defaultObj.type,
      data: { ...this.defaultObj.data, ...payload.data }
    };

    return data;
  }

  fireCall() {
    if (typeof this.trackMetrics === "undefined" || this.objArray.size === 0) {
      return;
    }

    for (const obj of this.objArray) {
      try {
        let finalObj = this.formData(obj);
        if (this.dev) console.log("trackMetrics call with", finalObj);
        this.trackMetrics(finalObj);
        this.objArray.delete(obj);
      } catch (e) {
        console.error("trackMetrics failed with error", e);
      }
    }
  }

  storeCall(obj) {
    this.objArray.add(obj);
  }
}

export default Analytics;

// window.analytics = new Analytics();

// document.addEventListener("DOMContentLoaded", function (event) {
//   analytics.bindTracking({
//     type: "dynamic-page"
//   });
// });

// const btn = document.getElementsByTagName("button")[0];
// btn.onclick = () => {
//   spinMe();
//   analytics.bindTracking({
//     type: "user-interaction",
//     data: {
//       interaction: "spinMe-click",
//       event_interaction: "1"
//     }
//   });
// };
