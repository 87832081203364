<template>
  <!-- Desktop Header -->
  <div
    class="container flex w-full md:justify-center justify-between items-center 2xl:h-32 xl:h-28 h-20 md:py-3 md:mt-4 mt-1 mb-2"
  >
    <img class="md:h-14 h-10" src="/assets/images/tune-in-date.svg" alt="" />
    <img
      @click="() => logoClick('Lander')"
      class="cursor-pointer md:h-full h-16 lg:px-20 md:px-16 px-4"
      src="/assets/images/logo-3d.png"
      alt=""
    />
    <img
      @click="() => youtubeClick('Lander')"
      class="cursor-pointer md:h-14 h-10"
      src="/assets/images/tune-in-landing.svg"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "LandingHeader",
  inject: ["youtubeClick", "logoClick"],
};
</script>

<style></style>
