<template>
  <div
    class="opacity-0 flex flex-col items-center lg:mt-20 md:mt-12 mt-8 z-1"
    ref="emailForm"
  >
    <div
      v-if="!success"
      class="text-white flex-grow-0 inline-flex relative border-l-2 border-r-2 border-white font-extra-condensed text-32 lg:text-42 font-black px-3 py-2 leading-none text-center"
    >
      <div class="absolute top-0 left-0 bg-white h-2px md:w-5 w-3"></div>
      <div class="absolute bottom-0 left-0 bg-white h-2px md:w-5 w-3"></div>
      <div class="absolute top-0 right-0 bg-white h-2px md:w-5 w-3"></div>
      <div class="absolute bottom-0 right-0 bg-white h-2px md:w-5 w-3"></div>
      <div class="border-left-top"></div>
      <div class="border-left-bottom"></div>
      <div class="border-right"></div>
      SET A REMINDER TO TUNE IN
    </div>

    <p
      v-if="!success"
      class="text-light-gold font-condensed px-8 md:mb-0 mb-2 lg:px-0 mt-4 text-center md:text-20 text-14 max-w-lg text-opacity-75 leading-6"
    >
      Enter your email address below to receive updates about the AEW Awards
      show and upcoming AEW events.
    </p>
    <div>
      <div class="w-full text-center font-extra-condensed md:px-0 px-6">
        <div v-if="!success" class="mt-3 lg:mt-7 flex flex-col lg:flex-row">
          <input
            @keyup.enter="signUp"
            class="w-auto lg:w-25vw flex-grow font-extra-condensed signup bg-transparent border border-white text-white text-18 placeholder:text-white placeholder:text-opacity-30"
            type="email"
            placeholder="EMAIL ADDRESS"
            v-model="email"
          />
          <div
            @click="signUp"
            class="flat-button min-h-50px mb-0 leading-normal items-center justify-center btn btn-white signup lg:w-52 lg:ml-4 rounded-sm w-full flex mt-3 lg:mt-zero tracking-wide"
          >
            Submit Email
          </div>
        </div>

        <div class="italic mt-2 text-light-yellow" v-if="error">
          {{ errorMsg }}
        </div>
        <div
          class="flex flex-col mt-6 bg-white bg-opacity-20 p-6"
          v-if="success"
        >
          <p class="headline text-42">{{ successMsg }}</p>
          <p class="text-light-yellow text-21 text-center mx-4">
            Click below for more content from your favorite AEW stars.
          </p>
          <a href="https://tbs.app.link/73LzwZQ8lnb" target="_blank">
            <div
              class="flat-button flex justify-center items-center mx-4 mt-2"
              @click="trackGetMoreClick"
            >
              Get More AEW
            </div>
          </a>
        </div>
      </div>
      <div
        v-if="!success"
        class="font-condensed text-center text-white text-opacity-50 md:text-14 text-12 md:mt-4 mt-2 md:px-0 px-6"
      >
        By submitting your email you agree to updates from AEW, TBS, TNT and
        WarnerMedia affiliates.
        <a
          class="underline hover:text-light-yellow transition duration-150"
          href="https://www.warnermediaprivacy.com/policycenter/b2c/WME/en-us/"
          target="_blank"
          >Privacy Policy</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import validator from "email-validator";
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      error: false,
      errorMsg: "Please enter a valid email address.",
      success: false,
      successMsg: "Thanks for signing up!",
    };
  },
  inject: ["trackInteraction"],
  mounted() {
    gsap.to(this.$refs.emailForm, { autoAlpha: 1, duration: 0.6, delay: 1.3 });
  },
  methods: {
    trackGetMoreClick() {
      this.trackInteraction({
        interactionName: "Get More AEW",
        widgetType: "CTA Button",
        widgetName: "Get More AEW",
        widgetItemName: "Get More AEW",
        screenName: "Voting Confirmation Page",
        screenSection: "Voting Confirmation Page",
      });

      window.open("https://tbs.app.link/73LzwZQ8lnb");
    },
    async signUp() {
      const isValid = validator.validate(this.email);
      if (isValid) {
        const res = await axios({
          method: "post",
          url: "https://t8f1jrhhba.execute-api.us-east-1.amazonaws.com/aew-awards-api", // PROD
          // url: "https://v3as6p8yh4.execute-api.us-east-1.amazonaws.com/api", // DEV
          data: {
            email: this.email,
          },
        });

        if (!res.data.errors.length) {
          this.error = false;
          this.email = "";
          this.success = true;

          this.trackInteraction({
            interactionName: "Email Signup",
            widgetType: "CTA Button",
            widgetName: "Email SignUp",
            widgetItemName: "Email SignUp",
            screenName: "Voting Confirmation Page",
            screenSection: "Voting Confirmation Page",
          });
        } else {
          this.error = true;
        }
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style>
@screen lg {
  .btn.signup {
    @apply mt-0;
    margin-top: 0 !important;
  }
}

.btn.signup {
  @apply flex;
}

input.signup {
  @apply px-4 py-2 leading-none;
  letter-spacing: 0.2em;
}
</style>
