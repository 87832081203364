<template>
  <div class="w-full mx-auto flex flex-col min-h-full" :class="currentScreen">
    <!-- Landing Screen -->
    <div
      v-if="showLandingScreen"
      ref="landingCtr"
      class="w-full mx-auto flex flex-grow justify-start md:justify-center flex-col items-center xl:px-24 lg:px-20 md:px-10 px-4 max-w-7xl min-h-full md:-mt-6 mt-4"
    >
      <LandingHeader
        class="landing-header opacity-0"
        v-if="showLandingScreen"
      />
      <div
        ref="heroOuter"
        class="opacity-0 video-outer relative md:aspect-w-16 aspect-w-12 md:aspect-h-7 aspect-h-10 w-full md:mt-0 mt-2 origin-center"
      >
        <div
          ref="heroInner"
          class="absolute overflow-hidden top-0 bg-black left-0 right-0 bottom-0 z-1 origin-center"
        >
          <video
            @loadeddata="animateLanding"
            ref="heroVideo"
            src="/assets/video/landing-hero.mp4"
            poster="/assets/images/landing-poster.jpg"
            class="object-cover h-full w-full relative z-1"
            playsinline
            autoplay
            muted
            loop
          />
        </div>
      </div>
      <div
        class="flex flex-col lg:w-9/12 w-11/12 xl:-mt-36 lg:-mt-30 md:-mt-32 -mt-16 z-10"
      >
        <div class="flex justify-center">
          <img
            ref="landingHeadline"
            src="/assets/images/landing-header.png"
            alt="The 2022 Fan Choice Awards"
            class="opacity-0 transform translate-y-10 2xl:w-9/12 xl:w-7/12 md:w-8/12 w-10/12"
          />
        </div>
        <div
          ref="landingCopy"
          class="opacity-0 transform translate-y-10 copy xl:px-18 md:px-10 md:mt-4 mt-2 mb-3"
        >
          You, the fans, get to choose your favorite wrestlers and moments from
          2021. Make your picks now through March 8, then stream the live show
          for free March 23 at 7 p.m. ET/4 p.m. PT only on AEW's
          <div
            class="inline underline text-opacity-75 hover:text-light-yellow transition duration-300 cursor-pointer"
            @click="openYouTube"
          >
            YouTube channel.
          </div>
        </div>
      </div>
      <div ref="landingBtn" class="opacity-0 transform translate-y-10">
        <div
          v-if="canVote"
          class="landing-button cursor-pointer md:px-16 px-12 py-4 md:mt-4 mt-2 text-white font-extrabold tracking-extra-wide"
          @click="startVoting"
        >
          <div class="landing-inner">Start voting</div>
        </div>
        <div
          v-else
          class="border-dark-gold border-2 bg-black bg-opacity-75 md:p-4 p-2 md:my-4 my-2 flex flex-col justify-center items-center text-center shadow-2xl md:max-w-lg"
        >
          <p
            class="text-dark-gold font-condensed font-bold uppercase md:text-30 text-21"
          >
            Sorry, you already voted today
          </p>
          <p class="text-white text-opacity-75 px-4 text-14 leading-tight">
            Come back tomorrow to vote again for your favorite AEW wrestlers and
            moments of 2021.
          </p>
        </div>
      </div>

      <!-- Legal Line -->
      <p
        ref="legalLine"
        class="opacity-0 transform translate-y-10 text-white md:text-14 text-11 text-opacity-50 font-condensed md:max-w-sm max-w-xs text-center leading-tight mt-3"
      >
        *All votes will be tallied by AEW, and the AEW team will have sole
        discretion to select the official winners.
      </p>
    </div>

    <!-- Voting Screens -->
    <div
      v-if="isVoting"
      class="w-full mx-auto md:px-8 px-2 flex flex-grow justify-center min-h-full 2xl:max-w-8xl max-w-7xl"
    >
      <div
        class="flex 2xl:items-center items-start"
        v-for="(category, idx) in categories"
        :key="idx"
      >
        <Category
          v-if="idx === activeCategoryIdx"
          :category="category"
          :next="next"
          :activeCategoryIdx="activeCategoryIdx"
          :idx="idx"
        >
        </Category>
      </div>
    </div>

    <!-- Final Screen -->
    <div
      v-if="showFinalScreen"
      class="flex flex-col flex-grow items-center justify-center min-h-full"
    >
      <FinalScreen />
    </div>

    <!-- Site Footer -->
    <SiteFooter />
    <div class="h-100vh w-100vw fixed top-0 bottom-0 -z-1">
      <video
        class="object-cover h-full w-full pointer-events-none"
        autoplay
        loop
        muted
        playsinline
        src="/assets/video/bg.mp4"
      ></video>
    </div>
  </div>
</template>

<script>
import LandingHeader from "@/components/LandingHeader";
import EventService from "./services/EventService";
import Category from "@/components/Category";
import SiteFooter from "@/components/SiteFooter";
import FinalScreen from "@/components/FinalScreen";
import { gsap } from "gsap";

export default {
  name: "App",
  components: {
    Category,
    SiteFooter,
    FinalScreen,
    LandingHeader,
  },

  data() {
    return {
      categories: null,
      activeCategoryIdx: 0,
      isVoting: false,
      hasAnsweredAllQuestions: false,
      canVote: false,
    };
  },
  inject: ["gui", "trackPageView", "trackInteraction"],
  async created() {
    this.setCanVote();
    this.categories = await EventService.getAllQuestions();

    this.gui.Register({
      type: "button",
      label: "Toggle Voting",
      folder: "Navigation",
      action: () => {
        this.canVote = !this.canVote;
      },
    });

    this.gui.Register({
      // A slider representing a value between 0 and 20
      type: "range",
      label: "Go to Cat #",
      folder: "Navigation",
      min: 0,
      max: this.categories.length - 1,
      step: 1,
      onChange: (value) => {
        this.isVoting = true;
        this.hasAnsweredAllQuestions = false;
        this.activeCategoryIdx = value;
      },
    });
  },
  mounted() {
    this.gui.Register([
      {
        type: "button",
        label: "Go to Landing Screen",
        folder: "Navigation",
        action: () => {
          this.hasAnsweredAllQuestions = false;
          this.isVoting = false;
        },
      },
      {
        type: "button",
        folder: "Navigation",
        label: "Go to Final Screen",
        action: () => {
          this.hasAnsweredAllQuestions = true;
          this.isVoting = false;
        },
      },
    ]);
  },
  computed: {
    showFinalScreen() {
      return this.hasAnsweredAllQuestions;
    },
    showLandingScreen() {
      return !this.isVoting && !this.hasAnsweredAllQuestions;
    },
    currentScreen() {
      let screen = "";
      if (this.activeCategoryIdx == 0) {
        screen = "landing";
      } else if (this.activeCategoryIdx == 1) {
        screen = "voting";
      } else if (this.activeCategoryIdx == 2) {
        screen = "end";
      }
      return screen;
    },
  },
  methods: {
    setCanVote() {
      const lastDayVoted = new Date(localStorage.getItem("lastVoted"));
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const msPerDay = 24 * 60 * 60 * 1000;
      const msSinceLastVote = today.getTime() - lastDayVoted.getTime();

      if (
        msSinceLastVote >= msPerDay ||
        process.env.NODE_ENV === "development"
      ) {
        this.canVote = true;
      } else {
        this.canVote = false;
      }
    },
    startVoting() {
      this.trackInteraction({
        interactionName: "Start Voting",
        widgetType: "CTA Button",
        widgetName: "Start Voting",
        widgetItemName: "Start Voting",
        screenName: "Lander",
        screenSection: "Lander",
      });
      this.trackPageView({
        interactionName: "Voting Page",
        screenName: "Voting Page",
        screenSection: "Voting Page",
      });
      gsap.to(this.$refs.landingCtr, {
        duration: 0.5,
        autoAlpha: 1,
        onComplete: () => {
          this.isVoting = true;
        },
      });
    },
    openYouTube() {
      this.trackInteraction({
        interactionName: "YouTube Channel Link",
        widgetType: "Link",
        widgetName: "Link",
        widgetItemName: "YouTube Channel",
        screenName: "Lander",
        screenSection: "Lander",
      });

      window.open("https://www.youtube.com/aew");
    },
    next() {
      if (this.activeCategoryIdx + 1 < this.categories.length) {
        this.activeCategoryIdx++;
      } else {
        this.hasAnsweredAllQuestions = true;
        this.isVoting = false;
      }
    },
    animateLanding() {
      let tl = gsap.timeline();

      // video container
      tl.from(
        this.$refs.heroOuter,
        {
          width: "0%",
          duration: 1,
          ease: "Power4.out",
        },
        "start"
      )
        // video container
        .to(
          this.$refs.heroOuter,
          {
            opacity: 1,
            duration: 0.2,
          },
          "start+=0.2"
        )
        // landing header
        .to(".landing-header", { opacity: 1, duration: 1 }, "start+=0.5")
        // landing headline
        .to(
          this.$refs.landingHeadline,
          { y: 0, autoAlpha: 1, duration: 0.5, ease: "Power4.out" },

          "start+=0.8"
        )
        // landing copy
        .to(
          this.$refs.landingCopy,
          { y: 0, autoAlpha: 1, duration: 0.5, ease: "Power4.out" },
          "start+=1.1"
        )
        // landing button and legal line
        .to(
          [this.$refs.landingBtn, this.$refs.legalLine],
          {
            y: 0,
            autoAlpha: 1,
            duration: 0.5,
            ease: "Power4.out",
            stagger: 0.1,
          },
          "start+=1.15"
        );
    },
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply w-full;
}
.video-outer::before {
  content: "";
  position: absolute;
  height: calc(100% + 2px);
  width: 10%;
  border-right: transparent;
  border-left: 1px solid #e6e0d4;
  border-top: 1px solid #e6e0d4;
  border-bottom: 1px solid #e6e0d4;
  left: -2px;
  top: -1px;
  box-shadow: 0px 0px 40px 0px rgba(230, 224, 212, 0.6);
  z-index: 0;
}
.video-outer::after {
  content: "";
  position: absolute;
  height: calc(100% + 2px);
  width: 10%;
  border-left: transparent;
  border-right: 1px solid #e6e0d4;
  border-top: 1px solid #e6e0d4;
  border-bottom: 1px solid #e6e0d4;
  right: -1px;
  top: -1px;
  box-shadow: 0px 0px 40px 0px rgba(230, 224, 212, 0.6);
  z-index: 0;
}
.landing {
  align-items: center;
}
.voting {
  align-items: top;
}
.inactive {
  pointer-events: none !important;
  opacity: 0.5 !important;
}
</style>
