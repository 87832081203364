<template>
  <div class="flex flex-col items-center max-w-7xl pt-4">
    <div class="flex flex-col justify-center">
      <div class="flex flex-col items-center">
        <div
          class="w-full h-full relative flex justify-center items-center lg:px-8"
        >
          <div class="w-28 hidden lg:block tune-in-block">
            <img
              @click="() => logoClick('Voting Confirmation Page')"
              class="w-full mr-4 cursor-pointer"
              src="/assets/images/logo-3d.png"
              alt="AEW Awards Logo"
            />
          </div>
          <!-- video -->
          <div
            ref="shareImage"
            class="opacity-0 transform translate-y-10 flex flex-col items-center 2xl:w-10/12 xl:w-9/12 md:w-10/12 w-11/12 md:px-6"
          >
            <img
              ref="finalHeader"
              class="opacity-0 md:w-8/12 w-11/12 z-1"
              src="/assets/images/thanks.png"
              alt="Thanks for Voting"
            />
            <img
              v-if="$isMobile()"
              class="w-full mt-4 border-2 border-light-gold"
              src="/assets/images/share-mobile.jpg"
              alt=""
            />
            <img
              v-else
              class="w-full md:-mt-6 border-2 border-light-gold"
              src="/assets/images/share-desktop.jpg"
              alt=""
            />
          </div>
          <div class="w-28 hidden lg:block tune-in-block">
            <img
              @click="() => youtubeClick('Voting Confirmation Page')"
              class="w-full cursor-pointer"
              src="/assets/images/tune-in.svg"
              alt="March 23 7pm et 4pm pt on YouTube"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center md:-mt-10 mt-2">
      <div class="flex flex-row space-x-7 mt-2">
        <div class="custom-bordered-icon" @click="shareToFacebook">
          <div class="absolute top-0 left-0 bg-light-yellow h-1px w-2"></div>
          <div class="absolute bottom-0 left-0 bg-light-yellow h-1px w-2"></div>
          <div class="absolute top-0 right-0 bg-light-yellow h-1px w-2"></div>
          <div
            class="absolute bottom-0 right-0 bg-light-yellow h-1px w-2"
          ></div>
          <svg
            class="md:h-8 h-6 md:w-8 w-6"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="rgb(230, 195, 126)"
          >
            <path
              d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
            />
          </svg>
        </div>
        <div class="custom-bordered-icon" @click="shareToTwitter">
          <div class="absolute top-0 left-0 bg-light-yellow h-2px w-2"></div>
          <div class="absolute bottom-0 left-0 bg-light-yellow h-2px w-2"></div>
          <div class="absolute top-0 right-0 bg-light-yellow h-2px w-2"></div>
          <div
            class="absolute bottom-0 right-0 bg-light-yellow h-2px w-2"
          ></div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="md:h-8 h-6 md:w-8 w-6"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="rgb(230, 195, 126)"
          >
            <path
              d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
            />
          </svg>
        </div>
        <div class="custom-bordered-icon" @click="download">
          <a download href="/assets/images/share-mobile.jpg">
            <div class="absolute top-0 left-0 bg-light-yellow h-2px w-2"></div>
            <div
              class="absolute bottom-0 left-0 bg-light-yellow h-2px w-2"
            ></div>
            <div class="absolute top-0 right-0 bg-light-yellow h-2px w-2"></div>
            <div
              class="absolute bottom-0 right-0 bg-light-yellow h-2px w-2"
            ></div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="md:h-8 h-6 md:w-8 w-6"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="rgb(230, 195, 126)"
            >
              <path
                d="M16 11h5l-9 10-9-10h5v-11h8v11zm3 8v3h-14v-3h-2v5h18v-5h-2z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
    <Email-Signup />
  </div>
</template>

<script>
import gsap from "gsap";
import EmailSignup from "@/components/EmailSignup";

export default {
  components: {
    EmailSignup,
  },
  inject: ["trackPageView", "trackInteraction", "youtubeClick", "logoClick"],
  mounted() {
    const now = new Date();
    const dayVoted = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    localStorage.setItem("lastVoted", dayVoted);
    this.trackPageView({
      interactionName: "Voting Confirmation Page",
      screenName: "Voting Confirmation Page",
      screenSection: "Voting Confirmation Page",
    });
    this.animateIn();
  },
  methods: {
    animateIn() {
      let tl = gsap.timeline();

      tl.to(
        this.$refs.shareImage,
        {
          autoAlpha: 1,
          y: 0,
          ease: "Power4.out",
          duration: 0.5,
        },
        "start"
      )

        .to(
          this.$refs.finalHeader,
          {
            autoAlpha: 1,
            duration: 0.5,
          },
          "start+=0.4"
        )
        .to(".tune-in-block", { autoAlpha: 1, duration: 1.2 }, "start+=0.6")
        .to(
          ".custom-bordered-icon",
          {
            autoAlpha: 1,
            duration: 0.5,
            y: 0,
            ease: "Power4.out",
            stagger: 0.08,
          },
          "start+=0.8"
        );
    },
    download() {
      this.trackInteraction({
        interactionName: "Download Click",
        widgetType: "Download Button",
        widgetName: "Download Click",
        widgetItemName: "Download",
        screenName: "Voting Confirmation Page",
        screenSection: "Voting Confirmation Page",
      });
    },
    shareToFacebook() {
      let shareURL = "https://aewawards.com";
      this.trackInteraction({
        interactionName: "Social Click",
        widgetType: "Social Button",
        widgetName: "Social Share",
        widgetItemName: "Facebook",
        screenName: "Voting Confirmation Page",
        screenSection: "Voting Confirmation Page",
      });

      window.open(
        "http://facebook.com/sharer/sharer.php?u=" +
          encodeURIComponent(shareURL),
        "",
        "left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
      );
    },
    shareToTwitter() {
      let siteURL = "https://aewawards.com";
      var shareURL = "http://twitter.com/share?"; //url base
      this.trackInteraction({
        interactionName: "Social Click",
        widgetType: "Social Button",
        widgetName: "Social Share",
        widgetItemName: "Twitter",
        screenName: "Voting Confirmation Page",
        screenSection: "Voting Confirmation Page",
      });

      //params
      var params = {
        url: siteURL,
        text: "I voted for the best wrestlers and moments in the #AEWAwards. Make your picks now at ",
      };
      for (var prop in params)
        shareURL += "&" + prop + "=" + encodeURIComponent(params[prop]);
      window.open(
        shareURL,
        "",
        "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
      );
    },
  },
};
</script>

<style>
.custom-bordered-icon {
  @apply text-light-yellow
        opacity-0
        transform
        translate-y-10
        cursor-pointer
        flex-grow-0
        bg-black
        bg-opacity-100
        inline-flex
        relative
        border-light-yellow
        font-extra-condensed
        text-48
        font-black
        px-4
        py-3
        leading-none;
  border-left-width: 1px;
  border-right-width: 1px;
  transition: background 250ms ease-in-out, fill 250ms ease-in-out;
}
.custom-bordered-icon:hover {
  @apply bg-light-yellow bg-opacity-100;
}
.custom-bordered-icon:hover svg {
  fill: rgb(21, 21, 21);
}
.tune-in-block {
  @apply opacity-0;
}
</style>
