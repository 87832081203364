<template>
  <div
    :class="{ disabled: !isActive, selected: selected }"
    class="nominee flat-button w-full md:max-w-xs flex justify-center items-center"
    @click="activateNominee"
  >
    <transition name="fade">
      <div v-if="selected" class="selected-inner"></div>
    </transition>

    <div class="inner-text transition duration-150">
      {{ answer.answer.answer.default }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["answer", "idx", "selectNominee", "isActive", "selected", "category"],
  inject: ["trackInteraction"],
  methods: {
    activateNominee() {
      this.trackInteraction({
        interactionName: "Nominee Click",
        widgetType: "CTA Button",
        widgetName: "Nominee Button",
        widgetItemName: this.answer.answer.answer.default,
        screenName: "Voting Page",
        screenSection: this.category,
      });
      this.selectNominee(this.answer, this.idx);
    },
  },
};
</script>

<style>
.nominee.disabled {
  @apply opacity-60;
}
.selected.nominee {
  @apply text-white;
}

.selected.nominee.flat-button::after {
  height: 0px;
}

.selected.nominee.inner-text {
  transform: translate(3px);
}

.selected.flat-button:hover::after {
  transform: scaleY(0);
}

.selected.flat-button:hover .inner-text {
  transform: translateY(0px);
}

.selected-inner {
  height: 100%;
  width: 100%;
  border: 1px solid white;
  position: absolute;
  transform-origin: center;
  transform: scale(1);
  transition: transform 300ms ease-in-out;
}

.fade-enter-active {
  transition: opacity 0.5s ease 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
