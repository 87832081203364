<template>
  <div class="w-full flex justify-center relative">
    <div
      class="font-extra-condensed text-16 tracking-wide uppercase flex flex-wrap w-90 md:flex-row md:w-full mx-2 justify-center text-light-yellow text-opacity-50 mt-6 mb-2 text-center"
    >
      <a
        class="md:px-10 px-4"
        target="_blank"
        href="https://www.tntdrama.com/terms"
        >Terms of Use</a
      >
      <a
        class="md:px-10 px-4"
        target="_blank"
        href="http://warnermediaprivacy.com/"
        >Privacy Policy</a
      >
      <a class="md:px-10 px-4 ot-sdk-show-settings"
        >Do Not Sell My Personal Information
      </a>
      <div class="md:px-0 px-4" id="tnt_adchoices" href="#"></div>
      <img
        src="https://i.cdn.turner.com/cnn/.element/img/3.0/global/misc/logo_ad_choices_footer.png"
        border="0"
        alt="Ad Choices"
        valign="middle"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
img[alt="Ad Choices"] {
  display: none;
}

#tnt_adchoices a {
  @apply md:px-10;
}

.ot-sdk-show-settings {
  cursor: pointer;
}
</style>
