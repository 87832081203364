export const categories = [
  {
    key: "mic",
    offsets: [6, 25, 33, 43, 55],
  },
  {
    key: "twitter",
    offsets: [6, 15, 22, 32, 41],
  },
  {
    key: "beatdown",
    offsets: [6, 14, 23, 33, 40],
  },
  {
    key: "surprise",
    offsets: [6, 20, 32, 42, 51],
  },
  {
    key: "wtf",
    offsets: [6, 15, 28, 39, 48],
  },
  {
    key: "female",
    offsets: [6, 16, 23, 33, 43],
  },
  {
    key: "male",
    offsets: [6, 17, 27, 40, 49],
  },
  {
    key: "highflyer",
    offsets: [6, 16, 23, 32, 40],
  },
  {
    key: "fashion",
    offsets: [6, 13, 22, 28, 36],
  },
  {
    key: "tagteam",
    offsets: [6, 16, 24, 35, 45],
  },
  {
    key: "duel",
    offsets: [6, 30, 44, 60, 74],
  },
  {
    key: "woy",
    offsets: [7, 19, 31, 38, 47, 57, 64],
  },
];
