import "./style.css";
import { createApp } from "vue";
import VueMobileDetection from "vue-mobile-detection";
import App from "./App.vue";
import { guify } from "guify";
import Analytics from "./Analytics";
import "./index.css";

const analytics = new Analytics();

const app = createApp(App);
app.use(VueMobileDetection);
const trackPageView = (payload) => {
  payload.micrositeName = "AEW AWARDS";
  payload.pageviewevent = 1;

  analytics.bindTracking.call(analytics, {
    type: "dynamic-page",
    data: payload,
  });
};

const trackInteraction = (payload) => {
  payload.micrositeName = "AEW AWARDS";
  payload.interaction = 1;

  analytics.bindTracking.call(analytics, {
    type: "user-interaction",
    data: payload,
  });
};

const loadImage = (src) => {
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = src;
    img.onload = function () {
      res();
    };

    img.onerror = function () {
      res();
    };
  });
};

app.provide("youtubeClick", (location) => {
  trackInteraction({
    interactionName: "Logo Click",
    widgetType: "Logo",
    widgetName: "Logo",
    widgetItemName: "Youtube Logo",
    screenName: location,
    screenSection: location,
  });
  window.open("https://www.youtube.com/aew");
});

app.provide("logoClick", (location) => {
  trackInteraction({
    interactionName: "Logo Click",
    widgetType: "Logo",
    widgetName: "Logo",
    widgetItemName: "AEW Logo",
    screenName: location,
    screenSection: location,
  });
  window.open("https://tbs.app.link/73LzwZQ8lnb");
});

if (process.env.NODE_ENV === "development") {
  const gui = new guify({
    title: "App Controls",
  });

  gui.Register({
    type: "folder",
    label: "Navigation",
    open: true,
  });
  app.provide("gui", gui);
} else {
  app.provide("gui", { Register: () => null });
}

app.provide("trackInteraction", trackInteraction);
app.provide("trackPageView", trackPageView);
app.provide("loadImage", loadImage);
app.mount("#app");
